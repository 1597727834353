'use client';

import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { AssetStoryblok } from '@/types/types-storyblok';
import { getImageDimensions } from '@/storyblok/utils/asset';
import Link from 'next/link';
import { DAVINCI_CDN } from '@/routes';

const HeaderLogo = ({ logo }: { logo?: AssetStoryblok }) => {
  const pathName = usePathname();
  return (
    <Link aria-current={pathName === '/' ? 'page' : undefined} href="/">
      <Image
        className="relative top-1"
        src={logo?.filename ?? `${DAVINCI_CDN}/elty/elty-logo.svg`}
        alt={logo?.alt ?? 'Elty logo'}
        {...(logo ? getImageDimensions(logo) : { width: 108, height: 33 })}
      />
    </Link>
  );
};

export default HeaderLogo;
