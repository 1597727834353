import { LinkStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { isSelectedLink } from '../utils';
import { usePathname } from 'next/navigation';
import Image from 'next/image';
import clsx from 'clsx';
import type { KeyboardEvent } from 'react';
import { isExternalLink, resolveWebLink } from '@/storyblok/utils/link';
import Link from 'next/link';
import { StoryblokStory } from 'storyblok-generate-ts';

type NavItemProps = {
  link: LinkStoryblok;
  isVisible: boolean;
  onLinkKeyDown: (e: KeyboardEvent) => void;
  story: StoryblokStory<StoryblokComponent>;
  component: StoryblokComponent;
};

export const NavItem = ({ link, isVisible, onLinkKeyDown, story, component }: NavItemProps) => {
  const pathName = usePathname();
  const isSelected = isSelectedLink(pathName, link);
  const icon = link.icon;
  const hasIcon = icon && icon.filename;
  const LinkEl = link.link && isExternalLink(link.link) ? 'a' : Link;
  return (
    <li className="w-full" key={link._uid} role="menuitem">
      {link.link && (
        <LinkEl
          data-external={isExternalLink(link.link)}
          aria-hidden={!isVisible}
          aria-current={isSelected ? 'page' : undefined}
          tabIndex={!isVisible || isSelected ? -1 : undefined}
          className={clsx(
            'flex items-center gap-x-2 justify-start focus:text-black hover:text-black transition-colors',
            isSelected ? 'text-black' : 'text-[#6B7280]',
          )}
          href={resolveWebLink(link.link, { additional: { link }, story, component })}
          onKeyDown={onLinkKeyDown}
        >
          {isSelected && !hasIcon && <div className="h-2.5 w-2.5 bg-elty-green rounded-full flex-shrink-0" />}
          {hasIcon && (
            <Image
              className={clsx('w-5 h-5 flex-shrink-0', !isSelected && 'opacity-70')}
              src={icon?.filename}
              alt={icon?.alt ?? ''}
              width={20}
              height={20}
            />
          )}
          <span>{link.title}</span>
        </LinkEl>
      )}
    </li>
  );
};
