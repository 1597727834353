'use client';

import Image from 'next/image';
import { useEffect, useState } from 'react';
import mobileMenuOpenIcon from '@/assets/menu-mobile-open-icon.svg';
import closeIcon from '@/assets/close-icon.svg';
import { LinkGroupStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import clsx from 'clsx';
import { isSelectedSection } from './utils';
import { usePathname } from 'next/navigation';
import { MobileHeaderNavSection } from './mobile/navSection';
import { StoryblokStory } from 'storyblok-generate-ts';

type MobileHeaderProps = {
  linkGroups: LinkGroupStoryblok[];
  story: StoryblokStory<StoryblokComponent>;
  component: StoryblokComponent;
};

export const MobileHeaderNav = ({ linkGroups: headerSections, story, component }: MobileHeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const pathName = usePathname();

  // close menu on path change
  useEffect(() => {
    setIsOpen(false);
    document.body.style.overflow = '';
  }, [pathName]);

  const toggle = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? 'hidden' : '';
  };

  return (
    <>
      <button className="xl:hidden relative z-20" onClick={toggle} aria-controls="mobile-menu" aria-expanded={isOpen}>
        <Image className="h-[22px] w-[22px]" src={isOpen ? closeIcon : mobileMenuOpenIcon} alt="Menu icon" />
      </button>
      <div
        className={clsx(
          'shadow-inset-1 h-screen-minus-header xl:hidden z-10 fixed bottom-0 left-0 w-screen transition-transform duration-300 flex flex-col',
          isOpen ? 'translate-x-0' : 'translate-x-full',
        )}
      >
        <nav id="mobile-menu" aria-hidden={!isOpen} className="pt-8 bg-white overflow-y-auto pb-20 flex-grow">
          <ul role="menubar">
            {headerSections.map(section => (
              <MobileHeaderNavSection
                isVisible={isOpen}
                selected={isSelectedSection(pathName, section)}
                key={section._uid}
                links={section}
                story={story}
                component={component}
              />
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};
