import * as Sentry from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';

export const captureMessage = async (message: string, captureContext?: CaptureContext) => {
  if (Sentry.getClient()) {
    Sentry.captureMessage(message, captureContext);
  } else if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    const { sendBuildMessage } = await import('./slackClient');

    await sendBuildMessage(message, captureContext);
  }

  console.warn(message, captureContext);
};
