import { KeyboardEvent } from 'react';
import { LinkGroupStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { useEffect, useRef, useState } from 'react';
import { NavItem } from './navItem';
import Image from 'next/image';
import clsx from 'clsx';
import chevronDownIcon from '@/assets/chevron-down.svg';
import { StoryblokStory } from 'storyblok-generate-ts';

type DesktopHeaderNavSectionProps = {
  links: LinkGroupStoryblok;
  selected?: boolean;
  story: StoryblokStory<StoryblokComponent>;
  component: StoryblokComponent;
};

export const DesktopHeaderNavSection = ({ links, selected, story, component }: DesktopHeaderNavSectionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLLIElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openedByMouseMove, setOpenedByMouseMove] = useState(false);
  const open = (byMouseMove?: boolean) => {
    setIsOpen(true);
    setOpenedByMouseMove(byMouseMove ?? false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setOpenedByMouseMove(false);
  };

  const onToggleButtonKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
        if (isOpen) setIsOpen(false);
        break;
      case 'ArrowDown':
        if (!isOpen) {
          setIsOpen(true);
        } else {
          const firstLink = containerRef.current?.querySelector('a');
          if (!firstLink) return;
          // prevent scroll
          e.preventDefault();
          firstLink.focus();
        }
        break;
      case 'ArrowLeft':
        const previousContainer = containerRef.current?.previousElementSibling;
        if (!previousContainer) return;
        const previousButton = previousContainer.querySelector('button');
        if (!previousButton) return;
        previousButton.focus();
        break;
      case 'ArrowRight':
        const nextContainer = containerRef.current?.nextElementSibling;
        if (!nextContainer) return;
        const nextButton = nextContainer.querySelector('button');
        if (!nextButton) return;
        nextButton.focus();
        break;
    }
  };

  const onLinkKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
        const previousLink = (e.target as HTMLElement).parentElement?.previousElementSibling?.querySelector('a');
        // prevent scroll
        e.preventDefault();
        if (!previousLink) {
          buttonRef.current?.focus();
        } else {
          previousLink.focus();
        }
        break;
      case 'ArrowDown':
        const nextLink = (e.target as HTMLElement).parentElement?.nextElementSibling?.querySelector('a');
        // prevent scroll
        e.preventDefault();
        if (nextLink) {
          nextLink.focus();
        }
        break;
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    const container = containerRef.current;
    if (!container) return;

    const focusInHandler = (e: FocusEvent) => {
      const focusedEl = e.target as HTMLElement;
      if (!container.contains(focusedEl)) {
        setIsOpen(false);
        document.removeEventListener('focusin', focusInHandler);
      }
    };

    const clickHandler = (e: MouseEvent) => {
      const clickedEl = e.target as HTMLElement;
      if (!containerRef.current?.contains(clickedEl)) {
        setIsOpen(false);
        document.removeEventListener('click', clickHandler);
      }
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      if (!openedByMouseMove) return;
      const hoveredEl = document.elementFromPoint(e.clientX, e.clientY);
      if (
        (!containerRef.current?.contains(hoveredEl) && !hoveredEl?.closest('header')) ||
        document.querySelectorAll('header li[role="menuitem"]>button[aria-expanded="true"]').length > 1
      ) {
        setIsOpen(false);
        setOpenedByMouseMove(false);
        window.removeEventListener('mousemove', mouseMoveHandler);
      }
    };

    document.addEventListener('focusin', focusInHandler);
    document.addEventListener('click', clickHandler);
    document.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      document.removeEventListener('focusin', focusInHandler);
      document.removeEventListener('click', clickHandler);
      document.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, [isOpen, openedByMouseMove]);

  return (
    <li ref={containerRef} role="menuitem">
      <button
        ref={buttonRef}
        className="flex flex-row items-center gap-2"
        onFocus={open.bind(null, false)}
        onMouseDown={toggle}
        onMouseMove={open.bind(null, true)}
        onKeyDown={onToggleButtonKeyDown}
        aria-expanded={isOpen}
        aria-controls={`desktop-menu-${links._uid}`}
      >
        {selected && <div className="h-2.5 w-2.5 bg-elty-green rounded-full" />}
        <span className="font-medium">{links.title}</span>
        <Image
          aria-hidden
          src={chevronDownIcon}
          alt="icona di interazione con il menù"
          className={clsx('transition-transform duration-150', !isOpen && 'rotate-180')}
        />
      </button>
      <nav
        role="menu"
        id={`desktop-menu-${links._uid}`}
        aria-hidden={!isOpen}
        className={clsx(
          'fixed top-[calc(var(--header-height)+var(--header-top-offset))] shadow-[0_2px_4px_0_#00000008,0_8px_8px_0_#00000008,inset_0px_7px_3px_-3px_#00000008] bg-white transition-all px-8 py-6 min-w-[240px] -translate-x-1/4 z-30',
          isOpen ? 'translate-y-0 opacity-100' : '-translate-y-8 opacity-0 pointer-events-none',
        )}
      >
        <ul className="flex flex-col justify-center items-center gap-5">
          {links.links.map(link => (
            <NavItem key={link._uid} link={link} isVisible={isOpen} onLinkKeyDown={onLinkKeyDown} story={story} component={component} />
          ))}
        </ul>
      </nav>
    </li>
  );
};
