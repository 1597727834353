import { LinkStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { usePathname } from 'next/navigation';
import { isSelectedLink } from '../utils';
import clsx from 'clsx';
import Image from 'next/image';
import { isExternalLink, resolveWebLink } from '@/storyblok/utils/link';
import Link from 'next/link';
import { StoryblokStory } from 'storyblok-generate-ts';

type NavItemProps = {
  link: LinkStoryblok;
  isVisible: boolean;
  story: StoryblokStory<StoryblokComponent>;
  component: StoryblokComponent;
};

export const NavItem = ({ link, isVisible, story, component }: NavItemProps) => {
  const pathName = usePathname();
  const isSelected = isSelectedLink(pathName, link);
  const icon = link.icon;
  const hasIcon = icon && icon.filename;
  const LinkEl = link.link && isExternalLink(link.link) ? 'a' : Link;
  return (
    <li key={link._uid} role="menuitem">
      {link.link && (
        <LinkEl
          tabIndex={!isVisible || isSelected ? -1 : undefined}
          aria-current={isSelected ? 'page' : undefined}
          className={clsx(
            'flex flex-row items-center gap-2 justify-start focus:text-black hover:text-black',
            isSelected ? 'text-black relative right-2' : 'text-[#6B7280]',
          )}
          href={resolveWebLink(link.link, { additional: { link }, story, component })}
        >
          {!hasIcon && isSelected && <div className="h-2.5 w-2.5 bg-elty-green rounded-full" />}
          {hasIcon && (
            <Image className={clsx(!isSelected && 'opacity-70')} src={icon?.filename} alt={icon?.alt ?? ''} width={20} height={20} />
          )}
          {link.title}
        </LinkEl>
      )}
    </li>
  );
};
