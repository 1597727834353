'use client';

import { useEffect, useRef, useState } from 'react';

const HEADER_HEIGHT = 78;

export const HeaderGlobalStyle = () => {
  const [headerTopOffset, setHeaderTopOffset] = useState(0);
  const previousYPosition = useRef(0);

  useEffect(() => {
    const scrollHandler = () => {
      if (previousYPosition.current === 0) {
        previousYPosition.current = window.scrollY;
        return;
      }

      if (window.scrollY <= HEADER_HEIGHT) {
        setHeaderTopOffset(0);
        return;
      }

      const delta = window.scrollY - previousYPosition.current;
      if (delta > 0) {
        setHeaderTopOffset(Math.max(-HEADER_HEIGHT, headerTopOffset - delta));
      } else {
        setHeaderTopOffset(Math.min(0, headerTopOffset - delta));
      }
      previousYPosition.current = window.scrollY;
    };
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [headerTopOffset]);

  return (
    <style jsx global>{`
      :root {
        --header-top-offset: ${headerTopOffset}px;
        --header-height: ${HEADER_HEIGHT}px;
      }
    `}</style>
  );
};
