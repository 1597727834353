'use client';

import { LinkGroupStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { isSelectedSection } from './utils';
import { usePathname } from 'next/navigation';
import { DesktopHeaderNavSection } from './desktop/navSection';
import { StoryblokStory } from 'storyblok-generate-ts';

export type DesktopHeaderNavProps = {
  linkGroups: LinkGroupStoryblok[];
  story: StoryblokStory<StoryblokComponent>;
  component: StoryblokComponent;
};

export const DesktopHeaderNav = ({ linkGroups, story, component }: DesktopHeaderNavProps) => {
  const pathName = usePathname();
  return (
    <nav className="hidden xl:block">
      <ul className="flex flex-row items-center gap-24 justify-end">
        {linkGroups.map(section => (
          <DesktopHeaderNavSection
            selected={isSelectedSection(pathName, section)}
            key={section._uid}
            links={section}
            story={story}
            component={component}
          />
        ))}
      </ul>
    </nav>
  );
};
