import { StoryblokComponent } from '@/types/types-storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';

export const buildMessageContext = ({
  story,
  component,
}: {
  story?: StoryblokStory<StoryblokComponent>;
  component?: StoryblokComponent;
}) => ({
  ...(story && {
    story: {
      uid: story.content._uid,
      slug: story.slug,
      full_slug: story.full_slug,
    },
  }),
  ...(component && {
    component: {
      uid: component._uid,
      component: component.component,
    },
  }),
});
